import { fetchData } from "../utils/fetchData";
import { postData } from "../utils/postData";
import { updateData } from "../utils/updateData";
import { deleteData } from "../utils/deleteData";

export const getSessions = async ({ page, pageSize, userId }) => {
  return await fetchData("/sessions", { page, pageSize, userId });
};

export const getSession = async id => {
  return await fetchData(`/sessions/${id}`);
};

export const createSession = async data => {
  return await postData("/sessions", data);
};

export const updateSession = async (id, data) => {
  return await updateData(`/sessions/${id}`, data);
};

export const deleteSession = async id => {
  return await deleteData(`/sessions/${id}`);
};

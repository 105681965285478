import { fetchData } from "../utils/fetchData";
import { postData } from "../utils/postData";
import { updateData } from "../utils/updateData";
import { deleteData } from "../utils/deleteData";

export const getTrainers = async ({ page, pageSize }) => {
  return await fetchData("/users", { page, pageSize });
};

export const getTrainer = async id => {
  return await fetchData(`/users/${id}`);
};

export const createTrainer = async data => {
  return await postData("/users/create", data);
};

export const updateTrainer = async (id, data) => {
  return await updateData(`/users/${id}`, data);
};

export const deleteTrainer = async id => {
  return await deleteData(`/users/${id}`);
};

import React from "react";

import DataTable from "../../components/data/DataTable";

const ShowData = ({ pageState, setPageState, columns, rows }) => {
  return (
    <DataTable
      getRowId={row => row._id}
      className="table-list"
      rows={rows}
      columns={columns}
      pageState={pageState}
      setPageState={setPageState}
    />
  );
};

export default ShowData;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useNavigate, useParams } from "react-router-dom";
import { Box, DialogContent, FormControl } from "@mui/material";
import { getCustomer, updateCustomer } from "../../Api/Customers";
import { getTrainers } from "../../Api/Trainers";
import { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Loading from "../../components/global/Loading";
import TextField from "@mui/material/TextField";
import { useNotification } from "../../context/NotificationContext";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateCustomer() {
  const { showNotification } = useNotification();
  const [open, setOpen] = useState(true);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const navigate = useNavigate();
  const [trainers, setTrainers] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    balance: 0,
    trainer: "",
    lastPayment: "",
  });

  const handleClose = () => {
    setOpen(false);
    navigate("/customers");
  };

  const handleSubmit = async () => {
    try {
      await updateCustomer(id, formData);
      showNotification("Müşteri güncellendi", "success");
      handleClose();
    } catch (error) {
      setError(error);
    }
  };

  const fetchTrainers = async () => {
    try {
      const response = await getTrainers({
        page: 1,
        pageSize: 100,
      });
      setTrainers(response.users || []);
    } catch (error) {
      setError(error);
    }
  };

  const fetchCustomer = async () => {
    try {
      const response = await getCustomer(id);
      console.log(response);
      setCustomer(response);
      setFormData({
        name: response.name,
        balance: response.balance,
        trainer: response.trainer,
        phone: response.phone,
        lastPayment: response?.lastPayment?.split("T")[0],
      });
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTrainers();
    fetchCustomer();
  }, []);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

              <Typography variant="h6">Müşteriyi Güncelle</Typography>
            </Box>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
              Kaydet
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="İsim Soyisim"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            name="phone"
            label="Telefon"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.phone}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="balance"
            label="Bakiye"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.balance}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="lastPayment"
            label="Son Ödeme Yapılan Tarih"
            type="date"
            fullWidth
            variant="outlined"
            value={formData.lastPayment}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="trainer-label">Eğitmen</InputLabel>
            <Select
              labelId="trainer-label"
              name="trainer"
              value={formData.trainer}
              onChange={handleChange}
              label="Eğitmen"
            >
              {trainers.map(trainer => (
                <MenuItem key={trainer._id} value={trainer._id}>
                  {trainer.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

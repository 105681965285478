// handlers.js

import { useSignIn } from "react-auth-kit";
import { loginUser } from "../Api/SignIn";
import { useNotification } from "../context/NotificationContext";

export const useHandleSubmit = () => {
  const signIn = useSignIn();
  const { showNotification } = useNotification();

  return async (values, navigate) => {
    try {
      const response = await loginUser(values);
      if (
        signIn({
          tokenType: "Bearer",
          expiresIn: 3600,
          token: response.token,
          authState: {
            _id: response._id,
            username: response.username,
            role: response.role,
            // email: response.user.email,
          },
        })
      ) {
        showNotification("Giriş Başarılı", "success");
        navigate("/");
      }
    } catch (err) {
      showNotification("Giriş Hatalı", "error");
    }
  };
};

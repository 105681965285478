import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { createCustomer } from "../../Api/Customers"; // Assume the function to fetch trainers
import { useNotification } from "../../context/NotificationContext";
import Loading from "../../components/global/Loading";
import ShowError from "../../components/global/ShowError";
import { getTrainers } from "../../Api/Trainers";

const CreateCustomer = ({ onCustomerCreated }) => {
  const { showNotification } = useNotification();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    trainer: "",
    balance: 0,
    lastPayment: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
    fetchTrainers(); // Fetch trainers when dialog opens
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await createCustomer(formData);
      showNotification("Müşteri başarıyla oluşturuldu", "success");

      if (onCustomerCreated) {
        onCustomerCreated();
      }
      setOpen(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTrainers = async () => {
    setLoading(true);
    try {
      const response = await getTrainers({
        page: 1,
        pageSize: 100,
      });
      setTrainers(response.users || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainers();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ShowError error={error} />;
  }

  return (
    <Box>
      <Button variant="contained" onClick={handleClickOpen}>
        Yeni Müşteri Ekle
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Yeni Müşteri Ekle</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Ad"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="phone"
            label="Telefon"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.phone}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            name="balance"
            label="Bakiye"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.balance}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="lastPayment"
            label="Son Ödeme"
            type="date"
            fullWidth
            variant="outlined"
            value={formData.lastPayment}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* Mui checkbox component */}
          <FormControl fullWidth margin="dense">
            <InputLabel id="trainer-label">Eğitmen</InputLabel>
            <Select
              labelId="trainer-label"
              name="trainer"
              value={formData.trainer}
              onChange={handleChange}
              label="Eğitmen"
            >
              {trainers.map(trainer => (
                <MenuItem key={trainer._id} value={trainer._id}>
                  {trainer.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button variant="contained" onClick={handleSubmit}>
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateCustomer;

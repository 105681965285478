import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { getReport, getReportByCustomer } from "../../Api/Dashboard";
import { getTrainers } from "../../Api/Trainers";
import { getCustomers } from "../../Api/Customers";
import Loading from "../../components/global/Loading";

const Home = () => {
  // mevcut ayin ilk gunu
  const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    .toISOString()
    .split("T")[0];

  // mevcut ayin son gunu
  const lastDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  )
    .toISOString()
    .split("T")[0];

  // getReport({ trainerIds: "66bcfbd5ac5b1020a340ade9", startDate: "2024-08-01", endDate: "2024-08-29" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [customersData, setCustomersData] = useState([]);
  const [totalCustomersEarnings, setTotalCustomersEarnings] = useState(0);
  const [totalCustomersDuration, setTotalCustomersDuration] = useState(0);
  const [customerDuration, setCustomerDuration] = useState(0);
  const [customerLastPayment, setCustomerLastPayment] = useState(null);
  const [trainers, setTrainers] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [trainer, setTrainer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [reportType, setReportType] = useState("trainer");

  const handleChangeTrainer = event => {
    setTrainer(event.target.value);
  };

  const handleChangeCustomer = event => {
    setCustomer(event.target.value);
  };

  const handleChangeStartDate = event => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = event => {
    setEndDate(event.target.value);
  };

  const fetchTrainers = async () => {
    try {
      const response = await getTrainers({ page: 1, pageSize: 100 });
      console.log(response);
      setTrainers(response.users);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await getCustomers({ page: 1, pageSize: 100 });
      console.log(response);
      setCustomers(response.customers);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const fetchReport = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getReport({
        trainerIds: trainer,
        startDate,
        endDate,
      });
      setData(response.sessions);
      setTotalEarnings(response.totalEarnings);
      setTotalDuration(response.totalDuration);
      console.log(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReportByCustomer = async () => {
    console.log("customer");
    setLoading(true);
    setError(null);
    console.log(customer);
    try {
      const response = await getReportByCustomer({
        customerIds: customer,
        startDate,
        endDate,
      });
      setCustomersData(response.sessions);
      setTotalCustomersEarnings(response.totalEarnings);
      setTotalCustomersDuration(response.totalDuration);
      setCustomerLastPayment(response.lastPayment);
      setCustomerDuration(response.duration);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainers();
    fetchCustomers();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* // Trainer, Start Date, End Date, Get Report button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          gap: 2,
        }}
      >
        <FormControl fullWidth margin="dense">
          <InputLabel id="report-type-label">Rapor Türü</InputLabel>
          <Select
            labelId="report-type-label"
            name="reportType"
            value={reportType}
            onChange={event => setReportType(event.target.value)}
            label="Rapor Türü"
          >
            <MenuItem value="trainer">Eğitmen Bazlı</MenuItem>
            <MenuItem value="customer">Müşteri Bazlı</MenuItem>
          </Select>
        </FormControl>
        {reportType === "trainer" && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="trainer-label">Eğitmen</InputLabel>
            <Select
              labelId="trainer-label"
              name="trainer"
              value={trainer}
              onChange={handleChangeTrainer}
              label="Eğitmen"
            >
              {trainers?.map(trainer => (
                <MenuItem key={trainer._id} value={trainer._id}>
                  {trainer.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {reportType === "customer" && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="customer-label">Müşteri</InputLabel>
            <Select
              labelId="customer-label"
              name="customer"
              value={customer}
              onChange={handleChangeCustomer}
              label="Müşteri"
            >
              {customers?.map(customer => (
                <MenuItem key={customer._id} value={customer._id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          margin="dense"
          name="startDate"
          label="Tarih"
          type="date"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={startDate ? startDate : new Date().toISOString().split("T")[0]}
          onChange={handleChangeStartDate}
        />
        <TextField
          margin="dense"
          name="endDate"
          label="Tarih"
          type="date"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={endDate ? endDate : new Date().toISOString().split("T")[0]}
          onChange={handleChangeEndDate}
        />
        <Button
          fullWidth
          size="large"
          sx={{ height: "50px", minHeight: "100%!important" }}
          onClick={
            reportType === "trainer" ? fetchReport : fetchReportByCustomer
          }
          variant="contained"
          color="primary"
        >
          Raporu Getir
        </Button>
      </Box>
      {/* // Report data */}
      {data && reportType === "trainer" && (
        <Box>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                border: "1px solid #ccc", // Tablonun tamamına border
                borderCollapse: "collapse", // Border'ların çakışmaması için
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Müşteri
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Tarih
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Saat
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Kredi
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Düet Ders
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Kazanç
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map(row => (
                  <TableRow key={row._id}>
                    <TableCell sx={{ border: "1px solid #ccc" }}>
                      {row.customer}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {new Date(row.date).toLocaleDateString("tr-TR")}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {row.time}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {row.duration}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {row.isDuet ? "Evet" : "Hayır"}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {row.earnings}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} sx={{ borderTop: "1px solid #ccc" }} />
                  <TableCell
                    align="left"
                    sx={{
                      borderTop: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                      fontWeight: "bold",
                    }} // Komple borderTop
                  >
                    Toplam Süre:
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ border: "1px solid #ccc", fontWeight: "bold" }} // Komple border
                  >
                    {totalDuration}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ borderTop: "1px solid #ccc" }} />
                  <TableCell
                    align="left"
                    sx={{
                      borderTop: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                      fontWeight: "bold",
                    }} // Komple borderTop
                  >
                    Toplam Ödeme:
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ border: "1px solid #ccc", fontWeight: "bold" }} // Komple border
                  >
                    {totalEarnings}
                    TL
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )}

      {customersData && reportType === "customer" && (
        <Box>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                border: "1px solid #ccc", // Tablonun tamamına border
                borderCollapse: "collapse", // Border'ların çakışmaması için
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Eğitmen
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Tarih
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Saat
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Düet Ders
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Harcanan Kredi
                  </TableCell>
                  <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                    Yapılan Ödeme
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customersData?.map(row => (
                  <TableRow key={row._id}>
                    <TableCell sx={{ border: "1px solid #ccc" }}>
                      {row.trainer}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {new Date(row.date).toLocaleDateString("tr-TR")}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {row.time}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {row.isDuet ? "Evet" : "Hayır"}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {row.duration}
                    </TableCell>
                    <TableCell align="left" sx={{ border: "1px solid #ccc" }}>
                      {row.earnings}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} sx={{ borderTop: "1px solid #ccc" }} />
                  <TableCell
                    align="left"
                    sx={{
                      borderTop: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                      fontWeight: "bold",
                    }} // Komple borderTop
                  >
                    Toplam Süre:
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ border: "1px solid #ccc", fontWeight: "bold" }} // Komple border
                  >
                    {totalCustomersDuration} Ders
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ borderTop: "1px solid #ccc" }} />
                  <TableCell
                    align="left"
                    sx={{
                      borderTop: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                      fontWeight: "bold",
                    }} // Komple borderTop
                  >
                    Kalan Bakiye:
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ border: "1px solid #ccc", fontWeight: "bold" }} // Komple border
                  >
                    {customerDuration} Ders
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ borderTop: "1px solid #ccc" }} />
                  <TableCell
                    align="left"
                    sx={{
                      borderTop: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                      fontWeight: "bold",
                    }} // Komple borderTop
                  >
                    Toplam Ödeme:
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ border: "1px solid #ccc", fontWeight: "bold" }} // Komple border
                  >
                    {totalCustomersEarnings}
                    TL
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{ borderTop: "1px solid #ccc" }} />
                  <TableCell
                    align="left"
                    sx={{
                      borderTop: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                      fontWeight: "bold",
                    }} // Komple borderTop
                  >
                    Son Ödeme Yapılan Tarih:
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ border: "1px solid #ccc", fontWeight: "bold" }} // Komple border
                  >
                    {new Date(customerLastPayment).toLocaleDateString(
                      "tr-TR"
                    ) === "01.01.1970"
                      ? "Son ödeme verisi bulunmamaktadır."
                      : new Date(customerLastPayment).toLocaleDateString(
                          "tr-TR"
                        )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )}

      {
        // Show nothing if there is no data

        !data && !error && (
          <Typography variant="h6" align="center">
            Lütfen rapor almak için eğitmen seçiniz ve tarih aralığını
            belirleyiniz.
          </Typography>
        )
      }

      {
        // eger data 404 ise
        error && (
          <Typography variant="h6" align="center">
            Bu tarih aralığında eğitmenin hiçbir randevusu bulunmamaktadır.
          </Typography>
        )
      }
    </Box>
  );
};

export default Home;

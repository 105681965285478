import { Typography, useTheme } from "@mui/material";
import React from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import {
  DashboardCustomizeTwoTone,
  FactoryTwoTone,
  FitnessCenterTwoTone,
  PaidTwoTone,
  PaymentsTwoTone,
  PeopleAltTwoTone,
  ReportGmailerrorredTwoTone,
  SportsKabaddiTwoTone,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { getRole } from "../../utils/getUserCredentials";

const SideNav = () => {
  const theme = useTheme();
  const location = useLocation();
  const role = getRole();
  return (
    <Sidebar
      style={{
        height: "100%",
        top: "auto",
      }}
      breakPoint="md"
      backgroundColor={theme.palette.white.main}
    >
      <Menu
        menuItemStyles={{
          button: ({ active }) => ({
            backgroundColor: active
              ? theme.palette.neutral.medium
              : "transparent",
          }),
        }}
      >
        <MenuItem
          active={location.pathname === "/"}
          component={<Link to={"/"} />}
          icon={<FitnessCenterTwoTone />}
        >
          <Typography variant="body">Dersler</Typography>
        </MenuItem>
        {role === "superadmin" && (
          <MenuItem
            active={
              location.pathname === "/trainers" ||
              location.pathname.includes("/trainers")
            }
            component={<Link to={"/trainers"} />}
            icon={<SportsKabaddiTwoTone />}
          >
            <Typography variant="body">Eğitmenler</Typography>
          </MenuItem>
        )}
        {role === "superadmin" && (
          <MenuItem
            active={
              location.pathname === "/customers" ||
              location.pathname.includes("/customers")
            }
            component={<Link to={"/customers"} />}
            icon={<PeopleAltTwoTone />}
          >
            <Typography variant="body">Müşteriler</Typography>
          </MenuItem>
        )}
        {role === "superadmin" && (
          <MenuItem
            active={location.pathname === "/reports"}
            component={<Link to={"/reports"} />}
            icon={<ReportGmailerrorredTwoTone />}
          >
            <Typography variant="body">Raporlar</Typography>
          </MenuItem>
        )}
      </Menu>
    </Sidebar>
  );
};

export default SideNav;

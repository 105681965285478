import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { createSession } from "../../Api/Sessions";
import { useNotification } from "../../context/NotificationContext";
import Loading from "../../components/global/Loading";
import ShowError from "../../components/global/ShowError";
import { getTrainers } from "../../Api/Trainers";
import { getCustomers } from "../../Api/Customers";
import { getUserCredentials } from "../../utils/getUserCredentials";

const CreateSession = ({ onSessionCreated }) => {
  const user = getUserCredentials();
  const { showNotification } = useNotification();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({
    trainer: "",
    customer: "",
    sessionType: "",
    date: "",
    time: "",
    duration: "",
    note: "",
    isDuet: false,
    isNewSession: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
    fetchTrainers();
    fetchCustomers();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await createSession(formData);
      showNotification("Ders başarıyla oluşturuldu", "success");

      if (onSessionCreated) {
        onSessionCreated();
      }

      // Reset form data
      setFormData({
        trainer: "",
        customer: "",
        sessionType: "",
        date: "",
        time: "",
        duration: "",
        note: "",
        isDuet: false,
        isNewSession: false,
      });

      setOpen(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTrainers = async () => {
    setLoading(true);
    try {
      const response = await getTrainers({
        page: 1,
        pageSize: 100,
      });
      setTrainers(response.users || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await getCustomers({
        page: 1,
        pageSize: 100,
        search: "",
      });
      setCustomers(response.customers || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      trainer: user._id,
    }));
    fetchTrainers();
    fetchCustomers();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ShowError error={error} />;
  }

  return (
    <Box>
      <Button variant="contained" onClick={handleClickOpen}>
        Yeni Ders Ekle
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Yeni Ders Ekle</DialogTitle>
        <DialogContent>
          {user.role !== "trainer" && (
            <FormControl fullWidth margin="dense">
              <InputLabel id="trainer-label">Eğitmen</InputLabel>
              <Select
                labelId="trainer-label"
                name="trainer"
                value={formData.trainer}
                onChange={handleChange}
                label="Eğitmen"
              >
                {trainers.map(trainer => (
                  <MenuItem key={trainer._id} value={trainer._id}>
                    {trainer.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth margin="dense">
            {/* <Select
              labelId="customer-label"
              name="customer"
              value={formData.customer}
              onChange={handleChange}
              label="Müşteri"
            >
              {customers.map(customer => (
                <MenuItem key={customer._id} value={customer._id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              options={customers}
              getOptionLabel={option => option.name}
              value={
                customers.find(
                  customer => customer._id === formData.customer
                ) || null
              }
              onChange={(e, value) => {
                setFormData(prevData => ({
                  ...prevData,
                  customer: value?._id || "",
                }));
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Müşteri"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          </FormControl>
          <TextField
            margin="dense"
            name="sessionType"
            label="Ders Türü"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.sessionType}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="date"
            label="Tarih"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={formData.date}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="time"
            label="Saat"
            type="time"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }} // Saat girişine odaklandığınızda label yukarı çıkacak
            value={formData.time}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="duration"
            label="Süre (saat)"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.duration}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="note"
            label="Not"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.note}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isDuet"
                checked={formData.isDuet}
                onChange={e =>
                  setFormData(prevData => ({
                    ...prevData,
                    isDuet: e.target.checked,
                  }))
                }
              />
            }
            label="Düet Ders"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isNewSession"
                checked={formData.isNewSession}
                onChange={e =>
                  setFormData(prevData => ({
                    ...prevData,
                    isNewSession: e.target.checked,
                  }))
                }
              />
            }
            label="Yeni Ders"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button variant="contained" onClick={handleSubmit}>
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateSession;

import { fetchData } from "../utils/fetchData";
import { postData } from "../utils/postData";
import { updateData } from "../utils/updateData";
import { deleteData } from "../utils/deleteData";

export const getCustomers = async ({ page, pageSize, search }) => {
  return await fetchData("/customers", { page, pageSize, search });
};

export const getCustomer = async id => {
  return await fetchData(`/customers/${id}`);
};

export const createCustomer = async data => {
  return await postData("/customers/create", data);
};

export const updateCustomer = async (id, data) => {
  return await updateData(`/customers/${id}`, data);
};

export const deleteCustomer = async id => {
  return await deleteData(`/customers/${id}`);
};

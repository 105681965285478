import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getTrainer, updateTrainer } from "../../Api/Trainers";
import { useNotification } from "../../context/NotificationContext";
import Loading from "../../components/global/Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateTrainer = () => {
  const { showNotification } = useNotification();
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [trainer, setTrainer] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    role: "",
    hourlyRate: 0,
    salaryRatio: 0,
    duetHourlyRate: 0,
    isNewSalary: false,
    newHourlyRate: 0,
    newDuetHourlyRate: 0,
  });

  const handleClose = () => {
    setOpen(false);
    navigate("/trainers");
  };

  const handleSubmit = async () => {
    try {
      await updateTrainer(id, formData);
      showNotification("Eğitmen başarıyla güncellendi.", "success");
      handleClose();
    } catch (error) {
      setError(error);
    }
  };

  const fetchTrainer = async () => {
    setLoading(true);
    try {
      const response = await getTrainer(id);
      setTrainer(response);
      setFormData({
        username: response.username,
        role: response.role,
        hourlyRate: response.hourlyRate,
        salaryRatio: response?.salaryRatio,
        duetHourlyRate: response?.duetHourlyRate,
        isNewSalary: response?.isNewSalary,
        newHourlyRate: response?.newHourlyRate,
        newDuetHourlyRate: response?.newDuetHourlyRate,
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainer();
  }, [id]);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Eğitmeni Güncelle</Typography>
          </Box>
          <Button autoFocus color="inherit" onClick={handleSubmit}>
            Kaydet
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <TextField
          margin="dense"
          name="username"
          label="Kullanıcı Adı"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.username}
          onChange={handleChange}
        />

        {/* Mui Select */}
        <FormControl fullWidth>
          <InputLabel id="role">Rol</InputLabel>
          <Select
            labelId="role"
            id="role"
            name="role"
            value={formData.role}
            label="Rol"
            onChange={handleChange}
          >
            <MenuItem value="trainer">Eğitmen</MenuItem>
            <MenuItem value="superadmin">Admin</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          name="hourlyRate"
          label="Saat Ücreti"
          type="number"
          fullWidth
          variant="outlined"
          value={formData.hourlyRate}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="duetHourlyRate"
          label="Duet Saat Ücreti"
          type="number"
          fullWidth
          variant="outlined"
          value={formData.duetHourlyRate}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="salaryRatio"
          label="Maaş Oranı"
          type="number"
          fullWidth
          variant="outlined"
          value={formData.salaryRatio}
          onChange={handleChange}
        />

        <FormControlLabel
          control={
            <Switch
              checked={formData.isNewSalary}
              onChange={e =>
                setFormData(prevData => ({
                  ...prevData,
                  isNewSalary: e.target.checked,
                }))
              }
            />
          }
          label="Yeni Ücret"
        />
        {formData.isNewSalary && (
          <>
            <TextField
              margin="dense"
              name="newHourlyRate"
              label="Yeni Saat Ücreti"
              type="number"
              fullWidth
              variant="outlined"
              value={formData.newHourlyRate}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="newDuetHourlyRate"
              label="Yeni Duet Saat Ücreti"
              type="number"
              fullWidth
              variant="outlined"
              value={formData.newDuetHourlyRate}
              onChange={handleChange}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateTrainer;

import { Navigate, Route, Routes } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";
import NotAuth from "../containers/Errors/NotAuth";
import { SignIn } from "../containers/Auth";
import Home from "../containers/Home/Home";
import Trainers from "../containers/Trainers/Trainers";
import Customers from "../containers/Customers/Customers";
import Sessions from "../containers/Sessions/Sessions";
import UpdateSession from "../containers/Sessions/UpdateSession";
import UpdateCustomer from "../containers/Customers/UpdateCustomer";
import UpdateTrainer from "../containers/Trainers/UpdateTrainer";
import { getRole } from "../utils/getUserCredentials";

function AppRoutes() {
  const PrivateRoute = ({ Component }) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? <Component /> : <Navigate to="/login" />;
  };

  return (
    <Routes>
      <Route path="/" element={<PrivateRoute Component={Sessions} />} />
      <Route path="/trainers" element={<PrivateRoute Component={Trainers} />} />
      <Route
        path="/trainers/:id"
        element={<PrivateRoute Component={UpdateTrainer} />}
      />
      <Route
        path="/customers"
        element={<PrivateRoute Component={Customers} />}
      />
      <Route
        path="/customers/:id"
        element={<PrivateRoute Component={UpdateCustomer} />}
      />

      <Route path="/reports" element={<PrivateRoute Component={Home} />} />

      <Route path="/sessions" element={<PrivateRoute Component={Sessions} />} />
      <Route path="/sessions/:id" element={<UpdateSession />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/not-auth" element={<NotAuth />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;

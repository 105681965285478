// Desc: Dashboard API
import { fetchData } from "../utils/fetchData";

export const getReport = async ({ trainerIds, startDate, endDate }) => {
  return await fetchData(
    `/sessions/reports/get-report?trainerIds=${trainerIds}&startDate=${startDate}&endDate=${endDate}`
  );
};

export const getReportByCustomer = async ({
  customerIds,
  startDate,
  endDate,
}) => {
  return await fetchData(
    `/sessions/reports/get-report-by-customer?customerIds=${customerIds}&startDate=${startDate}&endDate=${endDate}`
  );
};

// api.js

import axios from "axios";

const api = axios.create({
  baseURL: "https://api.studionutz.com/",
  // baseURL: "http://localhost:8000/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const loginUser = async userData => {
  console.log(userData);
  try {
    const response = await api.post("api/auth/login", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

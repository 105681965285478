import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { createTrainer } from "../../Api/Trainers";
import { useNotification } from "../../context/NotificationContext";
import Loading from "../../components/global/Loading";
import ShowError from "../../components/global/ShowError";

const CreateTrainer = ({ onTrainerCreated }) => {
  const { showNotification } = useNotification();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    role: "",
    hourlyRate: 0,
    duetHourlyRate: 0,
    salaryRatio: 0,
    isNewSalary: false,
    newHourlyRate: 0,
    newDuetHourlyRate: 0,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(formData);
    try {
      await createTrainer(formData);
      showNotification("Kullanıcı başarıyla oluşturuldu", "success");

      if (onTrainerCreated) {
        onTrainerCreated();
      }
      setOpen(false);
    } catch (error) {
      setError(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ShowError error={error} />;
  }

  return (
    <Box>
      <Button variant="contained" onClick={handleClickOpen}>
        Yeni Eğitmen Ekle
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Yeni Eğitmen Ekle</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Kullanıcı Adı"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.username}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="password"
            label="Şifre"
            type="password"
            fullWidth
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="role-label">Rol</InputLabel>
            <Select
              labelId="role-label"
              name="role"
              value={formData.role}
              onChange={handleChange}
              label="Rol"
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="trainer">Eğitmen</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="hourlyRate"
            label="Saatlik Ücret"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.hourlyRate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="duetHourlyRate"
            label="Duet Saatlik Ücret"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.duetHourlyRate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="salaryRatio"
            label="Maaş Oranı"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.salaryRatio}
            onChange={handleChange}
          />

          <FormControlLabel
            control={
              <Switch
                checked={formData.isNewSalary}
                onChange={e =>
                  setFormData(prevData => ({
                    ...prevData,
                    isNewSalary: e.target.checked,
                  }))
                }
              />
            }
            label="Yeni Ücret"
          />

          {formData.isNewSalary && (
            <>
              <TextField
                margin="dense"
                name="newHourlyRate"
                label="Yeni Saatlik Ücret"
                type="number"
                fullWidth
                variant="outlined"
                value={formData.newHourlyRate}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="newDuetHourlyRate"
                label="Yeni Duet Saatlik Ücret"
                type="number"
                fullWidth
                variant="outlined"
                value={formData.newDuetHourlyRate}
                onChange={handleChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button variant="contained" onClick={handleSubmit}>
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateTrainer;

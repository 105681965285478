import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Select,
} from "@mui/material";
import CreateSession from "./CreateSession";
import ShowData from "./ShowData";
import { getSessions, deleteSession } from "../../Api/Sessions";
import ShowError from "../../components/global/ShowError";
import Loading from "../../components/global/Loading";
import { useNavigate } from "react-router-dom";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { getUserCredentials } from "../../utils/getUserCredentials";
import { useNotification } from "../../context/NotificationContext";
import { getTrainers } from "../../Api/Trainers";

const Sessions = () => {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const user = getUserCredentials();
  const [error, setError] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
    userId: "",
  });

  const [selectedSession, setSelectedSession] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchData = async () => {
    setPageState(oldState => ({ ...oldState, isLoading: true }));
    try {
      const response = await getSessions({
        page: pageState.page + 1,
        pageSize: pageState.pageSize,
        userId: pageState.userId,
      });

      const transformedData = response.items.map(session => ({
        ...session,
        trainer: session?.trainer?.username,
        customer: session?.customer?.name,
      }));

      setPageState(oldState => ({
        ...oldState,
        isLoading: false,
        data: transformedData,
        total: response.totalItems,
      }));
    } catch (error) {
      setPageState(oldState => ({ ...oldState, isLoading: false }));
      setError(error);
    }
  };

  const fetchTrainers = async () => {
    try {
      const response = await getTrainers({
        page: 1,
        pageSize: 100,
      });
      setTrainers(response.users || []);
    } catch (error) {
      setError(error);
    }
  };

  const handleSessionCreated = () => {
    fetchData();
  };

  const handleDeleteClick = session => {
    setSelectedSession(session);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteSession(selectedSession._id);
      showNotification("Ders başarıyla silindi.", "success");
      fetchData();
    } catch (err) {
      setError(err);
      showNotification("Ders silinirken bir hata oluştu.", "error");
    } finally {
      setOpenDialog(false);
      setSelectedSession(null);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);

    setSelectedSession(null);
  };

  useEffect(() => {
    fetchTrainers();
  }, []);

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize, pageState.userId]);

  if (error) {
    return <ShowError error={error} />;
  }

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CreateSession onSessionCreated={handleSessionCreated} />

        {user.role === "superadmin" && (
          <Select
            size="small"
            native
            value={pageState.userId}
            onChange={e =>
              setPageState(oldState => ({
                ...oldState,
                userId: e.target.value,
              }))
            }
          >
            <option value="">Tümü</option>
            {trainers.map(trainer => (
              <option key={trainer._id} value={trainer._id}>
                {trainer.username}
              </option>
            ))}
          </Select>
        )}
      </Box>

      <ShowData
        rows={pageState.data}
        pageState={pageState}
        setPageState={setPageState}
        columns={[
          {
            field: "trainer",
            headerName: "Eğitmen",
            flex: 1,
          },
          { field: "customer", headerName: "Müşteri", flex: 1 },
          { field: "sessionType", headerName: "Ders Türü", flex: 1 },
          {
            field: "date",
            headerName: "Tarih",
            flex: 0.7,
            valueGetter: params =>
              new Date(params.row.date).toLocaleDateString("tr-TR"),
          },
          {
            field: "time",
            headerName: "Saat",
            flex: 0.5,
          },
          { field: "duration", headerName: "Kredi", flex: 0.3 },
          { field: "note", headerName: "Not", flex: 1 },
          user.role === "superadmin" && {
            field: "isDuet",
            headerName: "Düet Ders",
            flex: 0.5,
            valueGetter: params => (params.row.isDuet ? "Evet" : "Hayır"),
          },
          user.role === "superadmin" && {
            field: "actions",
            type: "actions",
            headerName: "Eylemler",
            flex: 0.5,
            renderCell: params => (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <IconButton
                  onClick={() => navigate(`/sessions/${params.row._id}`)}
                >
                  <EditTwoTone />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(params.row)}>
                  <DeleteTwoTone />
                </IconButton>
              </Box>
            ),
          },
        ]}
      />

      {/* Onay Modali */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Dersi Sil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bu dersi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            İptal
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sessions;

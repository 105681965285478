import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CreateCustomer from "./CreateCustomer";
import ShowData from "./ShowData"; // ShowData bileşeninin farklı veri türleri için tekrar kullanılabilir olduğunu varsayın
import { getCustomers, deleteCustomer } from "../../Api/Customers";
import { getTrainers } from "../../Api/Trainers"; // getTrainers fonksiyonunu içe aktarın
import ShowError from "../../components/global/ShowError";
import Loading from "../../components/global/Loading";
import { useNavigate } from "react-router-dom";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { useNotification } from "../../context/NotificationContext";

import { getRole } from "../../utils/getUserCredentials";

const Customers = () => {
  const { showNotification } = useNotification();
  const role = getRole();
  console.log(role);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [trainersMap, setTrainersMap] = useState({});
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
    search: "",
  });

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchTrainers = async () => {
    try {
      const response = await getTrainers({ page: 1, pageSize: 100 });
      const trainers = response.users || [];
      const trainerMap = {};
      trainers.forEach(trainer => {
        trainerMap[trainer._id] = trainer.username;
      });
      setTrainersMap(trainerMap);
    } catch (error) {
      setError(error);
    }
  };

  const fetchData = async () => {
    setPageState(oldState => ({ ...oldState, isLoading: true }));
    try {
      const response = await getCustomers({
        page: pageState.page + 1,
        pageSize: pageState.pageSize,
        search: pageState.search,
      });
      setPageState(oldState => ({
        ...oldState,
        isLoading: false,
        data: response.customers || [],
        total: response.totalCustomers || 0,
      }));
    } catch (error) {
      setPageState(oldState => ({ ...oldState, isLoading: false }));
      setError(error);
    }
  };

  const handleCustomerCreated = () => {
    fetchData();
  };

  const handleDeleteClick = customer => {
    setSelectedCustomer(customer);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteCustomer(selectedCustomer._id);
      showNotification("Müşteri başarıyla silindi.", "success");
      fetchData();
    } catch (err) {
      setError(err);
      showNotification("Müşteri silinirken bir hata oluştu.", "error");
    } finally {
      setOpenDialog(false);
      setSelectedCustomer(null);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCustomer(null);
  };

  const handleEdit = id => {
    navigate(`/customers/${id}`);
  };

  useEffect(() => {
    fetchTrainers();
    fetchData();
  }, [pageState.page, pageState.pageSize, pageState.search]);

  if (error) {
    return <ShowError error={error} />;
  }

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CreateCustomer onCustomerCreated={handleCustomerCreated} />
        <TextField
          size="small"
          label="Ara"
          variant="outlined"
          value={pageState.search}
          onChange={e =>
            setPageState(oldState => ({
              ...oldState,
              search: e.target.value,
            }))
          }
        />
      </Box>
      <ShowData
        rows={pageState.data}
        pageState={pageState}
        setPageState={setPageState}
        columns={[
          { field: "name", headerName: "Ad", flex: 1 },
          { field: "phone", headerName: "Telefon", flex: 1 },
          { field: "balance", headerName: "Bakiye", width: 150 },
          {
            field: "lastPayment",
            headerName: "Ödeme Tarihi",
            flex: 1,
            valueGetter: params =>
              params.row.lastPayment === null && params.row.balance < 0
                ? "Ödeme yapılmadı"
                : params.row.lastPayment === null
                ? " "
                : new Date(params.row.lastPayment).toLocaleDateString("tr-TR"),
          },
          role === "superadmin" && {
            field: "actions",
            headerName: "İşlemler",
            flex: 0.5,
            renderCell: params => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <IconButton onClick={() => handleEdit(params.row._id)}>
                  <EditTwoTone />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(params.row)}>
                  <DeleteTwoTone />
                </IconButton>
              </Box>
            ),
          },
        ]}
      />

      {/* Onay Modali */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Müşteriyi Sil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bu müşteriyi silmek istediğinizden emin misiniz? Bu işlem geri
            alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            İptal
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Customers;

import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CreateTrainer from "./CreateTrainer";
import ShowData from "./ShowData";
import { deleteTrainer, getTrainers } from "../../Api/Trainers";
import ShowError from "../../components/global/ShowError";
import Loading from "../../components/global/Loading";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { useNotification } from "../../context/NotificationContext";
import { useNavigate } from "react-router-dom";

const Trainers = () => {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchData = async () => {
    setPageState(oldState => ({ ...oldState, isLoading: true }));
    try {
      const response = await getTrainers({
        page: pageState.page + 1,
        pageSize: pageState.pageSize,
      });
      setPageState(oldState => ({
        ...oldState,
        isLoading: false,
        data: response.users || [],
        total: response.totalUsers || 0,
      }));
    } catch (error) {
      setPageState(oldState => ({ ...oldState, isLoading: false }));
      setError(error);
    }
  };

  const handleTrainerCreated = () => {
    fetchData();
  };

  const handleDeleteClick = trainer => {
    setSelectedTrainer(trainer);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteTrainer(selectedTrainer._id);
      showNotification("Eğitmen başarıyla silindi", "success");
      fetchData();
    } catch (err) {
      setError(err);
      showNotification("Eğitmen silinirken bir hata oluştu", "error");
    } finally {
      setOpenDialog(false);
      setSelectedTrainer(null);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTrainer(null);
  };

  const handleEdit = id => {
    navigate(`/trainers/${id}`);
  };

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  if (pageState.isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ShowError error={error} />;
  }

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <CreateTrainer onTrainerCreated={handleTrainerCreated} />
      <ShowData
        rows={pageState.data}
        pageState={pageState}
        setPageState={setPageState}
        columns={[
          { field: "username", headerName: "Kullanıcı Adı", flex: 1 },
          {
            field: "role",
            headerName: "Rol",
            flex: 1,
            valueGetter: params =>
              params.row.role === "trainer" ? "Eğitmen" : "Admin",
          },
          { field: "hourlyRate", headerName: "Saat Ücreti", flex: 1 },
          { field: "duetHourlyRate", headerName: "Duet Saat Ücreti", flex: 1 },
          { field: "salaryRatio", headerName: "Ücret Oranı % ", flex: 1 },
          {
            field: "actions",
            headerName: "İşlemler",
            flex: 1,
            renderCell: params => (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <IconButton onClick={() => handleEdit(params.row._id)}>
                  <EditTwoTone />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(params.row)}>
                  <DeleteTwoTone />
                </IconButton>
              </Box>
            ),
          },
        ]}
      />

      {/* Onay Modali */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Eğitmeni Sil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bu eğitmeni silmek istediğinizden emin misiniz? Bu işlem geri
            alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>İptal</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Trainers;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, useParams } from "react-router-dom";
import { Box, DialogContent, FormControl } from "@mui/material";
import { getCustomers } from "../../Api/Customers";
import { getTrainers } from "../../Api/Trainers";
import { getSession, updateSession } from "../../Api/Sessions";
import { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Loading from "../../components/global/Loading";
import TextField from "@mui/material/TextField";
import { useNotification } from "../../context/NotificationContext";
import { getUserCredentials } from "../../utils/getUserCredentials";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateSession() {
  const role = getUserCredentials().role;
  const { showNotification } = useNotification();
  const [open, setOpen] = useState(true);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [session, setSession] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({
    trainer: "",
    customer: "",
    sessionType: "",
    date: null,
    time: "",
    duration: 0,
    note: "",
    isDuet: false,
    isNewSession: false,
  });

  const handleClose = () => {
    setOpen(false);
    navigate("/sessions");
  };

  const handleSubmit = async () => {
    try {
      await updateSession(id, formData);
      console.log("Session updated");
      showNotification("Ders güncellendi", "success");
      handleClose();
    } catch (error) {
      setError(error);
    }
  };

  const fetchTrainers = async () => {
    try {
      const response = await getTrainers({
        page: 1,
        pageSize: 100,
      });
      setTrainers(response.users || []);
    } catch (error) {
      setError(error);
    }
  };

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await getCustomers({
        page: 1,
        pageSize: 100,
      });
      setCustomers(response.customers || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSession = async () => {
    try {
      const response = await getSession(id);
      setSession(response);
      setFormData({
        trainer: response.trainer._id,
        customer: response.customer._id,
        sessionType: response.sessionType,
        date: new Date(response.date).toISOString().split("T")[0],
        time: response.time,
        duration: response.duration,
        note: response.note,
        isDuet: response.isDuet,
        isNewSession: response?.isNewSession,
      });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchTrainers();
    fetchCustomers();
    fetchSession();
    setLoading(false);
  }, [id]);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

              <Typography variant="h6">Dersi Güncelle</Typography>
            </Box>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
              Kaydet
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="customer-label">Müşteri</InputLabel>
            <Select
              labelId="customer-label"
              name="customer"
              value={formData.customer}
              onChange={handleChange}
              label="Müşteri"
            >
              {customers.map(customer => (
                <MenuItem key={customer._id} value={customer._id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="trainer-label">Eğitmen</InputLabel>
            <Select
              labelId="trainer-label"
              name="trainer"
              value={formData.trainer}
              onChange={handleChange}
              label="Eğitmen"
            >
              {trainers.map(trainer => (
                <MenuItem key={trainer._id} value={trainer._id}>
                  {trainer.username}
                </MenuItem>
              ))}
            </Select>
            <TextField
              margin="dense"
              name="sessionType"
              label="Ders Türü"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.sessionType}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="date"
              label="Tarih"
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formData.date}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="time"
              label="Saat"
              type="time"
              fullWidth
              variant="outlined"
              value={formData.time}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="duration"
              label="Süre (Saat)"
              type="number"
              fullWidth
              variant="outlined"
              value={formData.duration}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="note"
              label="Not"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.note}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isDuet"
                  checked={formData.isDuet}
                  onChange={e =>
                    setFormData(prevData => ({
                      ...prevData,
                      isDuet: e.target.checked,
                    }))
                  }
                />
              }
              label="Düet Ders"
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                name="isNewSession"
                checked={formData.isNewSession}
                onChange={e =>
                  setFormData(prevData => ({
                    ...prevData,
                    isNewSession: e.target.checked,
                  }))
                }
              />
            }
            label="Yeni Ders"
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
